import { useContext } from "react";
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import AuthContext from "../contexts/AuthProvider";
// import axios from "axios";
import axios from "../api/axios";

const Header = () => {
  const { auth } = useAuth();
  const { setAuth } = useContext(AuthContext);

  const logoutHandler = async () => {
    try {
      await axios.get("api/auth/logout", {
        withCredentials: true,
      });
      setAuth({});
    } catch (error) {
      setAuth({});
    }
  };

  const toggleUserMenu = () => {
    document.getElementById("userDropdown").classList.toggle("hidden");
  };

  window.onclick = (event) => {
    if (!event.target.matches(".userMenuButton")) {
      if (document.getElementById("userDropdown")) {
        if (
          !document.getElementById("userDropdown").classList.contains("hidden")
        ) {
          document.getElementById("userDropdown").classList.add("hidden");
        }
      }
    }
  };

  const toggleMobileMenu = () => {
    document.getElementById("mobileMenu").classList.toggle("hidden");
    // console.log(document.getElementById("userDropdown").classList)
  };

  return (
    <div className="min-h-full max-w-screen-xl">
      <nav className="bg-green-600 fixed top-0 z-30 h-16 max-w-screen-xl w-full">
        <div className="mx-auto px-3">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              {auth?.userId ? (
                <div className="hidden md:block">
                  <div className="flex items-baseline space-x-4">
                    <Link to={"/"}>
                      <button
                        className="w-full flex items-center text-gray-300 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        type="button"
                      >
                        Events
                      </button>
                    </Link>
                    <Link to={"/"}>
                      <button
                        className="w-full flex items-center text-gray-300 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        type="button"
                      >
                        Other Link
                      </button>
                    </Link>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {/* <!-- Mobile menu button (hamburger) --> */}
              {auth?.userId ? (
                <div>
                  <div className="md:hidden flex items-center">
                    <button
                      className="outline-none mobile-menu-button"
                      onClick={toggleMobileMenu}
                    >
                      <svg
                        className="w-6 h-6 text-gray-300 text-sm font-medium"
                        x-show="!showMenu"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M4 6h16M4 12h16M4 18h16"></path>
                      </svg>
                    </button>
                  </div>

                  {/* <!-- Mobile menu --> */}
                  <div className="dropdown2">
                    <div
                      id="mobileMenu"
                      className="dropdown-content hidden absolute bg-white  text-base z-50 float-left py-2 list-none text-left rounded shadow-lg"
                    >
                      <Link to={"/"}>
                        <button
                          className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                          onClick={toggleMobileMenu}
                        >
                          Events
                        </button>
                      </Link>
                      <Link to={"/"}>
                        <button
                          className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                          onClick={toggleMobileMenu}
                        >
                          Other Link
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              <div className="w-40 flex-shrink-0 bg-green-600 px-4 py-3">
                {auth?.userId ? (
                  <div className="dropdown2">
                    <button
                      className="userMenuButton w-full flex items-center justify-end text-gray-300 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                      type="button"
                      onClick={toggleUserMenu}
                    >
                      {auth?.name}
                    </button>
                    <div
                      id="userDropdown"
                      className="dropdown-content hidden absolute bg-white  text-base z-50 float-left py-2 list-none text-left rounded shadow-lg"
                    >
                      <Link to={"/profile"}>
                        <button
                          className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                          onClick={toggleUserMenu}
                        >
                          Profile
                        </button>
                      </Link>
                      <a
                        className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                        // className="ml-5 px-4 py-1 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"

                        //  TODO: the redirect for oauth is hardcoded into this url???? For production I had to change it and deploy
                        // href="https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=http%3A%2F%2Flocalhost%3A5000%2Fauth%2Fgoogle%2Fcallback&client_id=905878810211-t4nvslsbe6d1ab8df78pm00blo7i4g8p.apps.googleusercontent.com&access_type=offline&response_type=code&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fphotoslibrary.readonly"
                        href="https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https%3A%2F%2Fwww.60elements.com%3A5000%2Fauth%2Fgoogle%2Fcallback&client_id=905878810211-t4nvslsbe6d1ab8df78pm00blo7i4g8p.apps.googleusercontent.com&access_type=offline&response_type=code&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fphotoslibrary.readonly"
                      >
                        {/* <button
                  // onClick={getPhotos}
                  className="ml-5 px-4 py-1 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                > */}
                        Google Connect
                        {/* </button> */}
                      </a>

                      {auth?.isAdmin && (
                        <div>
                          <Link to={"/"}>
                            <button className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
                              Admin Future Link
                            </button>
                          </Link>
                        </div>
                      )}
                      <button
                        onClick={(e) => {
                          logoutHandler(e);
                        }}
                        className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                ) : (
                  <Link to={`/login`}>
                    <button
                      className="w-full flex items-center justify-end text-gray-300 hover:bg-purple-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                      type="button"
                    >
                      Sign In
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
