import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Message from "../components/Message.js";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const CreateSegmentScreen = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const { state } = useLocation();
  const { event } = state;

  const [errMsg, setErrMsg] = useState("");
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [endDate, setEndDate] = useState(undefined);
  const [newSegment, setNewSegment] = useState({
    success: false,
  });

  const { error, success } = newSegment;

  useEffect(() => {
    if (!auth?.userId) {
      navigate("/login");
    }
  }, [auth]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (success) {
      setNewSegment({ success: false });
      navigate(from, { state: { event } });
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleCreate = (e) => {
    e.preventDefault();
    if (!name) {
      setErrMsg("Event name can't be blank");
      return;
    }
    if (!startDate) {
      setErrMsg("Segment must contain a start date");
      return;
    }
    createSegment({
      eventId: event.eventId,
      name,
      startDate,
      endDate,
    });
  };

  const handleCancel = () => {
    navigate(from, { state: { event } });
  };

  const createSegment = async (newSegment) => {
    try {
      setNewSegment({ loading: true, success: false });
      const { data } = await axiosPrivate.post(`/api/segments`, newSegment);
      setNewSegment({
        loading: false,
        success: true,
        createdSegment: data,
      });
    } catch (error) {
      // Refresh token expired
      if (error.response?.status === 403) {
        navigate("/login", { state: { from: location }, replace: true });
      }
      setNewSegment({
        loading: false,
        success: false,
        createdSegment: null,
        error: error.response?.data?.message
          ? error.response.data.message
          : error.message,
      });
    }
  };

  return (
    <section className="h-screen">
      <div className="px-6 mt-16 text-gray-800">
        <div className="flex justify-center items-center flex-wrap g-6">
          <div className="max-w-xs">
            <div className="flex justify-center py-8">
              <h1 className="text-2xl font-semibold">Create New Segment</h1>
            </div>
            {error && <Message variant="danger" text={error} />}
            {errMsg && <Message variant="danger" text={errMsg} />}
            <form onSubmit={handleCreate}>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    value={name}
                    onChange={handleNameChange}
                    type="text"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="name"
                    placeholder="Name"
                  />
                </div>
                <div className="w-full sm:w-1/2 px-3 mb-6">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="startDate"
                  >
                    Start Date
                  </label>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    value={startDate}
                    onChange={handleStartDateChange}
                    min="2000-01-01"
                    max="2030-12-31"
                    className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  />
                </div>
                <div className="w-full sm:w-1/2 px-3 mb-6">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="endDate"
                  >
                    End Date
                  </label>
                  <input
                    type="date"
                    id="endDate"
                    name="endDate"
                    value={endDate}
                    onChange={handleEndDateChange}
                    min="2000-01-01"
                    max="2030-12-31"
                    className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  />
                </div>

                <div className="text-center px-3 mt-6">
                  <button
                    onClick={handleCreate}
                    className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    Create
                  </button>
                </div>
                <div className="text-center px-3 mt-6">
                  <button
                    onClick={handleCancel}
                    className="inline-block px-7 py-3 bg-red-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateSegmentScreen;
