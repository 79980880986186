import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Message from "../components/Message.js";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CreateTextNodeScreen = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const { state } = useLocation();
  const { segment, event } = state;

  const [errMsg, setErrMsg] = useState("");
  const [editorText, setEditorText] = useState(null);
  const [newTextNode, setNewTextNode] = useState({
    success: false,
  });

  const { error, success } = newTextNode;

  useEffect(() => {
    if (!auth?.userId) {
      navigate("/login");
    }
  }, [auth]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (success) {
      setNewTextNode({ success: false });
      navigate(from, { state: { segment, event } });
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreate = (e) => {
    e.preventDefault();
    if (!editorText) {
      setErrMsg("Text node can't be blank");
      return;
    }

    createTextNode({
      segmentId: segment.segmentId,
      // note: JSON.stringify(editorText),
      note: editorText,
    });
  };

  const handleCancel = () => {
    navigate(from, { replace: true, state: { segment, event } });
  };

  const createTextNode = async (newTextNode) => {
    try {
      setNewTextNode({ loading: true, success: false });
      const { data } = await axiosPrivate.post(`/api/textNodes`, newTextNode);
      setNewTextNode({
        loading: false,
        success: true,
        createdTextNode: data,
      });
    } catch (error) {
      // Refresh token expired
      if (error.response?.status === 403) {
        navigate("/login", { state: { from: location }, replace: true });
      }
      setNewTextNode({
        loading: false,
        success: false,
        createdTextNode: null,
        error: error.response?.data?.message
          ? error.response.data.message
          : error.message,
      });
    }
  };

  return (
    <section className="h-screen">
      <div className="px-6 mt-16 text-gray-800">
        <div className="flex justify-center items-center flex-wrap g-6">
          <div className="max-wmd">
            <div className="flex justify-center py-8">
              <h1 className="text-2xl font-semibold">Create New Event</h1>
            </div>
            {error && <Message variant="danger" text={error} />}
            {errMsg && <Message variant="danger" text={errMsg} />}
            <form onSubmit={handleCreate}>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 mb-6 md:mb-0">
                  {/* <TextEditor initialText="" setEditorText={setEditorText} /> */}
                  <ReactQuill
                    theme="snow"
                    value={editorText}
                    onChange={setEditorText}
                  />
                </div>

                <div className="text-center px-2 mt-6">
                  <button
                    onClick={handleCreate}
                    className="inline-block px-2 py-2 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    Create
                  </button>
                </div>
                <div className="text-center px-2 mt-6">
                  <button
                    onClick={handleCancel}
                    className="inline-block px-2 py-2 bg-red-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateTextNodeScreen;
