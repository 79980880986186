import React from "react";
import { useNavigate } from "react-router-dom";

const EventCard = ({ event }) => {
  const navigate = useNavigate();

  const handleEventCard = (event) => {
    // console.log(event);
    navigate("/event", { state: { event } });
  };

  return (
    <>
      <button
        onClick={() => handleEventCard(event)}
        className="min-w-66 px-4 py-3 border-b-2 border-x-2 shadow-lg bg-gray-200 focus:bg-gray-300 focus:outline-none"
      >
        <div className="flex items-start">
          <div className="text-sm text-gray-900 font-semibold">
            {event.name}
          </div>
        </div>
      </button>
      <div className="bg-green-50 py-2"></div>
    </>
  );
};

export default EventCard;
