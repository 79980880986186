import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { axiosPrivate } from "../api/axios";
import useIsMounted from "../hooks/useIsMounted";

const EditText = ({ textNode, toggleEditor }) => {
  const [editorText, setEditorText] = useState(textNode.note);
  const mounted = useIsMounted();

  // Save changes to text only on component unmount
  useEffect(() => {
    return () => {
      if (!mounted()) {
        if (editorText) {
          saveNoteChange();
        }
      }
    };
  }, [mounted]); // eslint-disable-line react-hooks/exhaustive-deps

  const saveNoteChange = async () => {
    await axiosPrivate.put(`api/textnodes/${textNode.textNodeId}`, {
      note: editorText,
    });
  };

  return (
    <div>
      <ReactQuill theme="snow" value={editorText} onChange={setEditorText} />
      {/* <TextEditor
        initialText={JSON.parse(textNode.note)}
        setEditorText={setEditorText}
      /> */}

      <div className="flex flex-row justify-between">
        <button
          type="button"
          className=" px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          onClick={toggleEditor}
        >
          Close and Save
        </button>
      </div>
    </div>
  );
};

export default EditText;
