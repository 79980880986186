import axios from "axios";

const BASE_URL = "http://localhost:5000";
export default axios.create({
  baseURL: process.env.NODE_ENV === "development" ? BASE_URL : "",
});

export const axiosPrivate = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? BASE_URL : "",
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
