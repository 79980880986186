import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ProfileScreen from "./screens/ProfileScreen";
import PersistLogin from "./components/PersistLogin";
import EventScreen from "./screens/EventScreen";
import SegmentScreen from "./screens/SegmentScreen";
import CreateEventScreen from "./screens/CreateEventScreen";
import CreateSegmentScreen from "./screens/CreateSegmentScreen";
import CreateTextNodeScreen from "./screens/CreateTextNodeScreen";
import SegmentPhotosScreen from "./screens/SegmentPhotosScreen";
import PhotoNodeScreen from "./screens/PhotoNodeScreen";
import VideoNodeScreen from "./screens/VideoNodeScreen";

const App = () => {
  return (
    <Router>
      <Header />
      <main className="max-w-screen-xl">
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/register" element={<RegisterScreen />} />

          <Route element={<PersistLogin />}>
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/event" element={<EventScreen />} exact />
            <Route path="/segment" element={<SegmentScreen />} exact />
            <Route path="/createevent" element={<CreateEventScreen />} exact />
            <Route
              path="/createsegment"
              element={<CreateSegmentScreen />}
              exact
            />
            <Route
              path="/createtextnode"
              element={<CreateTextNodeScreen />}
              exact
            />
            <Route
              path="/segmentphotos"
              element={<SegmentPhotosScreen />}
              exact
            />
            <Route path="/photonode" element={<PhotoNodeScreen />} exact />
            <Route path="/videonode" element={<VideoNodeScreen />} exact />
            <Route path="/" element={<HomeScreen />} exact />
          </Route>
        </Routes>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
