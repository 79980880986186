import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Message from "../components/Message.js";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const CreateEventScreen = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [errMsg, setErrMsg] = useState("");
  const [name, setName] = useState(null);
  const [newEvent, setNewEvent] = useState({
    success: false,
  });

  const { error, success } = newEvent;

  useEffect(() => {
    if (!auth?.userId) {
      navigate("/login");
    }
  }, [auth]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (success) {
      setNewEvent({ success: false });
      setName("");
      navigate(from, { replace: true });
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleCreate = (e) => {
    e.preventDefault();
    if (!name) {
      setErrMsg("Event name can't be blank");
      return;
    }
    createEvent({ userId: auth.userId, name });
  };

  const handleCancel = () => {
    navigate(from, { replace: true });
  };

  const createEvent = async (newEvent) => {
    try {
      setNewEvent({ loading: true, success: false });
      const { data } = await axiosPrivate.post(`/api/events`, newEvent);
      setNewEvent({
        loading: false,
        success: true,
        createdEvent: data,
      });
    } catch (error) {
      // Refresh token expired
      if (error.response?.status === 403) {
        navigate("/login", { state: { from: location }, replace: true });
      }
      setNewEvent({
        loading: false,
        success: false,
        createdEvent: [],
        error: error.response?.data?.message
          ? error.response.data.message
          : error.message,
      });
    }
  };

  return (
    <section className="h-screen">
      <div className="px-6 mt-16 text-gray-800">
        <div className="flex justify-center items-center flex-wrap g-6">
          <div className="max-w-xs">
            <div className="flex justify-center py-8">
              <h1 className="text-2xl font-semibold">Create New Event</h1>
            </div>
            {error && <Message variant="danger" text={error} />}
            {errMsg && <Message variant="danger" text={errMsg} />}
            <form onSubmit={handleCreate}>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    value={name}
                    onChange={handleNameChange}
                    type="text"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="name"
                    placeholder="Name"
                  />
                </div>
                <div className="text-center px-3 mt-6">
                  <button
                    onClick={handleCreate}
                    className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    {" "}
                    Create
                  </button>
                </div>
                <div className="text-center px-3 mt-6">
                  <button
                    onClick={handleCancel}
                    className="inline-block px-7 py-3 bg-red-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    {" "}
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateEventScreen;
