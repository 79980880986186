/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

const PhotoAvailableCard = ({ photo, changeBorder }) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div onClick={() => changeBorder(photo.photoId)}>
      <img
        id={photo.photoId}
        src={photo.baseUrl}
        alt="test"
        className={`h-48 p-1 m-1 ${
          photo.selected ? "border-4 border-indigo-900" : ""
        }`}
      />
      <div className="bg-green-50 py-2"></div>
    </div>
  );
};

export default PhotoAvailableCard;
