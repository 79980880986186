import React, { useState, useEffect } from "react";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import TextNodeCard from "../components/TextNodeCard";
import PhotoNodeCard from "../components/PhotoNodeCard";
import VideoNodeCard from "../components/VideoNodeCard";

function SegmentScreen() {
  const location = useLocation();
  // const { segment, event } = location.state;
  const segment = location.state?.segment;
  const event = location.state?.event;

  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [textNodeList, setTextNodeList] = useState({
    loading: false,
    textNodes: [],
  });
  const [photoNodeList, setPhotoNodeList] = useState({
    loading: false,
    photoNodes: [],
  });
  const [videoNodeList, setVideoNodeList] = useState({
    loading: false,
    videoNodes: [],
  });

  const navigate = useNavigate();
  const { textNodes, loadingText, errorText } = textNodeList;
  const { photoNodes, loadingPhoto, errorPhoto } = photoNodeList;
  const { videoNodes, loadingVideo, errorVideo } = videoNodeList;
  const getTextNodes = async (segment) => {
    try {
      if (!loadingText && textNodes.length === 0) {
        setTextNodeList((prevState) => ({ ...prevState, loadingText: true }));
        const { data } = await axiosPrivate.get(`/api/textnodes`, {
          params: { segmentId: segment.segmentId },
        });
        setTextNodeList({ loadingText: false, textNodes: data });
      }
    } catch (error) {
      // console.log(`Error Response: ${JSON.stringify(error.response)}`);
      // Refresh token expired
      if (error.response?.status === 403) {
        navigate("/login", { state: { from: location }, replace: true });
      }
      setTextNodeList({
        loadingText: false,
        textNodes: [],
        errorText: error.response?.data?.message
          ? error.response.data.message
          : error.message,
      });
    }
  };

  const getPhotoNodes = async (segment) => {
    if (!loadingPhoto && photoNodes.length === 0) {
      try {
        setPhotoNodeList((prevState) => ({ ...prevState, loading: true }));
        const { data } = await axiosPrivate.get(`/api/photonodes`, {
          params: { segmentId: segment.segmentId },
        });

        const validatedPhotoNodes = await Promise.all(
          data.map(async (node) => {
            // download photo from file server
            const response = await axiosPrivate.get(
              `/api/photos/downloadweb`,
              { params: { photoId: node.id, cameraFilename: node.filename } },
              { responseType: "blob" }
            );
            // console.log(response);
            // const blob = await response.blob(); //new Blob([response.data]); //
            const url = URL.createObjectURL(response.data);
            // console.log(url);
            return { ...node, uri: url };
          })
        );
        // console.log(validatedPhotoNodes);

        setPhotoNodeList({
          loadingPhoto: false,
          photoNodes: validatedPhotoNodes,
        });
      } catch (error) {
        // console.log(`Error Response: ${JSON.stringify(error.response)}`);
        // Refresh token expired
        if (error.response?.status === 403) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        setPhotoNodeList({
          loadingPhoto: false,
          photoNodes: [],
          errorPhoto: error.response?.data?.message
            ? error.response.data.message
            : error.message,
        });
      }
    }
  };

  const getVideoNodes = async (segment) => {
    if (!loadingVideo && videoNodes.length === 0) {
      try {
        setVideoNodeList((prevState) => ({ ...prevState, loading: true }));
        const { data } = await axiosPrivate.get(`/api/videonodes`, {
          params: { segmentId: segment.segmentId },
        });
        const validatedVideoNodes = await Promise.all(
          data.map(async (node) => {
            // download video from file server
            const response = await axiosPrivate.get(
              `/api/videos/downloadthumbnail/${node.id}`,
              { responseType: "arraybuffer" }
            );
            // console.log("returned from getting video");
            // console.log(response);
            // const blob = await response.blob();
            const blob = new Blob([response.data], { type: "video/mp4" }); //
            const url = URL.createObjectURL(blob);
            // console.log(url);
            return { ...node, uriThumbnail: url };
          })
        );
        // console.log(validatedVideoNodes);

        setVideoNodeList({
          loadingVideo: false,
          videoNodes: validatedVideoNodes,
        });
      } catch (error) {
        // console.log(`Error Response: ${JSON.stringify(error.response)}`);
        // Refresh token expired
        if (error.response?.status === 403) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        setVideoNodeList({
          loadingVideo: false,
          videoNodes: [],
          errorVideo: error.response?.data?.message
            ? error.response.data.message
            : error.message,
        });
      }
    }
  };

  const addNewTextNode = () => {
    navigate("/createtextnode", { state: { from: location, segment, event } });
  };

  const handleClose = () => {
    // console.log(event);
    navigate("/event", { state: { event } });
  };

  useEffect(() => {
    if (!auth?.userId) {
      navigate("/login");
    } else {
      getTextNodes(segment);
      getPhotoNodes(segment);
      getVideoNodes(segment);
    }
  }, [auth, segment]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className="h-screen bg-gray-100">
      <div className="mt-16 text-gray-800">
        <div className="flex flex-col justify-center items-center w-full bg-green-50">
          <div className="flex justify-center py-4">
            <div className="flex flex-col items-center">
              <h1 className="m-2 text-2xl font-semibold">{segment?.name}</h1>
              <div className="flex flex-row">
                <button
                  onClick={addNewTextNode}
                  className="ml-5 px-2 py-2 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  Add Text
                </button>
                {/* <Link
                  to="/segmentphotos"
                  state={{
                    photos: photoSegmentList,
                    photoNodes: photoNodes,
                    segment: segment,
                    event: event,
                    from: location,
                  }}
                >
                  <button
                    className="ml-5 px-2 py-2 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    type="button"
                  >
                    Select Photos
                  </button>
                </Link> */}
                <button
                  onClick={handleClose}
                  className="ml-5 px-2 py-2 bg-red-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-shrink-0  bg-gray-100 ">
            {loadingText ? (
              <Loader />
            ) : errorText ? (
              <Message variant="danger" text={errorText} />
            ) : (
              <div>
                <div className="">
                  {textNodes.map((textNode) => (
                    <TextNodeCard
                      key={textNode.textNodeId}
                      textNode={textNode}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="">
            {loadingPhoto ? (
              <Loader />
            ) : errorPhoto ? (
              <Message variant="danger" text={errorPhoto} />
            ) : (
              <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
                {photoNodes.map((photoNode) => (
                  <PhotoNodeCard
                    key={photoNode.photoNodeId}
                    photoNode={photoNode}
                    segment={segment}
                    event={event}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="">
            {loadingVideo ? (
              <Loader />
            ) : errorVideo ? (
              <Message variant="danger" text={errorVideo} />
            ) : (
              <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
                {videoNodes.map((videoNode) => (
                  <VideoNodeCard
                    key={videoNode.videoNodeId}
                    videoNode={videoNode}
                    segment={segment}
                    event={event}
                  />
                ))}
              </div>
            )}
          </div>
          {/* <div className="">
            {loadingVideo ? (
              <Loader />
            ) : errorVideo ? (
              <Message variant="danger" text={errorVideo} />
            ) : (
              <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
                {videoNodes.map((videoNode) => (
                  <VideoNodeCard
                    key={videoNode.videoNodeId}
                    videoNode={videoNode}
                    segment={segment}
                    event={event}
                  />
                ))}
              </div>
            )}
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default SegmentScreen;
