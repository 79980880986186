import React, { useEffect, useState } from "react";
import PhotoAvailableCard from "../components/PhotoAvailableCard";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function SegmentPhotosScreen() {
  const { state } = useLocation();
  // console.log(state);
  const { photos, photoNodes, segment, event } = state;

  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [photosStatus, setPhotosStatus] = useState();

  useEffect(() => {
    setPhotosStatus(
      photos?.map((photo) => {
        if (photoNodes.find((pn) => pn.photoId === photo.photoId)) {
          return {
            photoId: photo.photoId,
            baseUrl: photo.baseUrl,
            selected: true,
          };
        } else {
          return {
            photoId: photo.photoId,
            baseUrl: photo.baseUrl,
            selected: false,
          };
        }
      })
    );
  }, []);

  useEffect(() => {
    if (!auth?.userId) {
      navigate("/login");
    }
  }, [auth]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeBorder = (photoId) => {
    const selectedPhoto = photosStatus.find(
      (photo) => photo.photoId === photoId
    );
    if (!selectedPhoto.selected) {
      document.getElementById(photoId).style.border = "thick solid indigo";
      setPhotosStatus(
        photosStatus.map((photo) =>
          photo.photoId === photoId
            ? { ...photo, selected: true }
            : { ...photo }
        )
      );
    } else {
      document.getElementById(photoId).style.border = "none";
      setPhotosStatus(
        photosStatus.map((photo) =>
          photo.photoId === photoId
            ? { ...photo, selected: false }
            : { ...photo }
        )
      );
    }
  };

  const handleAddPhotoNodes = () => {
    const selectedPhotos = photosStatus.filter((photo) => {
      return photo.selected;
    });
    // Photo Nodes to add to segment
    const addPhotoNodes = selectedPhotos.filter((sp) =>
      photoNodes.every((pn) => pn.photoId !== sp.photoId)
    );
    // Photo Nodes to remove from segment
    const removePhotoNodes = photoNodes.filter((pn) =>
      selectedPhotos.every((sp) => sp.photoId !== pn.photoId)
    );

    // Insert new phtoto nodes selected
    addPhotoNodes.map(async (photoNode) => {
      await axiosPrivate.post(`/api/photoNodes`, {
        segmentId: segment.segmentId,
        photoId: photoNode.photoId,
      });
    });
    // Delete photo nodes no longer selected
    removePhotoNodes.map(async (photoNode) => {
      await axiosPrivate.delete("/api/photonodes", {
        data: { photoNodeId: photoNode.photoNodeId },
      });
    });
    navigate(from, { state: { segment, event } });
  };

  const handleCancel = () => {
    navigate(from, { state: { segment, event } });
  };

  return (
    <section className="h-screen bg-gray-100">
      <div className="mt-16 text-gray-800">
        <div className="flex flex-col justify-center items-center w-full bg-green-50">
          <div className="flex py-4">
            <div className="flex sm:flex-row flex-col">
              <h1 className="text-center text-2xl font-semibold">
                {segment.name}
              </h1>
              <div>
                <button
                  onClick={handleAddPhotoNodes}
                  className="inline-block px-7 py-3 mx-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  Save Photos
                </button>
                <button
                  onClick={handleCancel}
                  className="inline-block px-7 py-3 bg-red-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className=" bg-gray-100 ">
            <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
              {photosStatus?.map((photo) => (
                <PhotoAvailableCard
                  key={photo.photoId}
                  photo={photo}
                  changeBorder={changeBorder}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SegmentPhotosScreen;
