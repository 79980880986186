import React from "react";
import { useNavigate } from "react-router-dom";

const SegmentCard = ({ segment, event }) => {
  const navigate = useNavigate();

  const handleSegmentCard = (segment) => {
    navigate("/segment", { state: { segment, event } });
  };

  return (
    <>
      <button
        onClick={() => handleSegmentCard(segment)}
        className="min-w-66 px-4 py-3 border-b-2 border-x-2 shadow-lg hover:bg-gray-200 focus:bg-gray-300 focus:outline-none"
      >
        <div className="flex flex-row justify-between">
          <div className="text-sm text-gray-900 font-semibold">
            {segment.name}
          </div>
          <div className="text-sm text-gray-600">
            {segment.startDate.substring(0, 10)}
          </div>
        </div>
      </button>
      <div className="bg-green-50 py-2"></div>
    </>
  );
};

export default SegmentCard;
