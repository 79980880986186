// Disabled to allow textCard to be clickable as a <div> (initially used button causing text formatting issues)
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { useNavigate } from "react-router-dom";
// import useAxiosPrivate from "../hooks/useAxiosPrivate";

const PhotoNodeCard = ({ photoNode }) => {
  // const [isPhotoNodeOpen, setPhotoNodeIsOpen] = useState(false);
  // const [photoNodeLocal, setPhotoNodeLocal] = useState(photoNode);
  // const axiosPrivate = useAxiosPrivate();

  // const togglePhotoNode = () => {
  //   setPhotoNodeIsOpen(!isPhotoNodeOpen);
  // };

  const navigate = useNavigate();

  const handleOpenNode = () => {
    navigate("/photonode", { state: { photoNode } });
  };

  // If returning from editor, get the updated photoNode from the database
  // useEffect(() => {
  //   if (!isPhotoNodeOpen) {
  //     getPhotoNode();
  //   }
  // }, [isPhotoNodeOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  // const getPhotoNode = async () => {
  //   try {
  //     const { data } = await axiosPrivate.get(
  //       `/api/photonodes/${photoNodeLocal.photoNodeId}`
  //     );
  //     setPhotoNodeLocal(data);

  //   } catch (error) {
  //     // TODO: manage the errors
  //     return error;
  //   }
  // };
  // console.log(photoNodeLocal.uri);

  return (
    <>
      {/* {isPhotoNodeOpen && (
        <PhotoNodeModal
          photoNode={photoNode}
          onRequestClose={togglePhotoNode}
          segment={segment}
          event={event}
        />
      )} */}
      <div className="p-1" onClick={handleOpenNode}>
        <img src={photoNode.uri} alt={photoNode.photoNodeId} className="h-48" />
        <div className="text-xs w-36 text-ellipsis overflow-hidden">
          {photoNode.label}
        </div>
        <div className="bg-green-50 py-2"></div>
      </div>
    </>
  );
};

export default PhotoNodeCard;
