import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const PhotoNodeScreen = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const { photoNode } = location.state;

  useEffect(() => {
    if (!auth?.userId) {
      navigate("/login");
    }
  }, [auth]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="mt-20">
      <img src={photoNode.uri} alt={photoNode.photoNodeId} className="h-96" />
      <div className="text-xs w-36 text-ellipsis overflow-hidden">
        {photoNode.label}
      </div>
    </div>
  );
};
export default PhotoNodeScreen;
