import React, { useEffect } from "react";

const ShowText = ({ textNode, toggleEditor, onRequestClose }) => {
  // Use useEffect to add an event listener to the document
  useEffect(() => {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        // Close the modal when the Escape key is pressed
        onRequestClose();
      }
    }
    // Prevent scolling
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onKeyDown);
    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", onKeyDown);
    };
  });
  return (
    <div className="flex flex-col justify-between min-h-96 ">
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: textNode.note }}
      ></div>
      <div className="flex flex-row justify-between">
        <button
          type="button"
          className={`px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out`}
          onClick={toggleEditor}
        >
          Edit
        </button>
        <button
          type="button"
          className=" px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          onClick={onRequestClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ShowText;
