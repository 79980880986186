import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Message from "../components/Message.js";
// import axios from "axios";
import axios from "../api/axios";
import { MdCheck, MdClose, MdInfoOutline } from "react-icons/md";

const NAME_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const RegisterScreen = () => {
  const nameRef = useRef();

  const [name, setName] = useState("");
  const [validName, setValidName] = useState("");
  const [nameFocus, setNameFocus] = useState("");

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [message, setMessage] = useState("");

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const location = useLocation();
  const redirect = location.search ? location.search.split("=")[1] : "/";

  const navigate = useNavigate();

  useEffect(() => {
    nameRef.current.focus();
  }, []);

  useEffect(() => {
    setValidName(NAME_REGEX.test(name));
  }, [name]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidPassword(PASSWORD_REGEX.test(password));
    setValidMatch(password === confirmPassword);
  }, [password, confirmPassword]);

  useEffect(() => {
    setErrMsg("");
  }, [name, email, password, confirmPassword]);

  useEffect(() => {
    if (success) {
      navigate("/login", {
        state: {
          message: {
            variant: "success",
            text: "Registation successful, please sign in",
          },
        },
      });
    }
  }, [success, navigate, redirect]);

  const registerUser = async (userData) => {
    try {
      const { data } = await axios.post("api/auth/register", userData, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      setMessage(data);
      setSuccess(true);
      setName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Server Response");
      } else if (error.response?.status === 409) {
        setErrMsg("User already exists with that email");
      } else {
        setErrMsg("Registration Failed");
      }
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const v1 = NAME_REGEX.test(name);
    const v2 = EMAIL_REGEX.test(email);
    const v3 = PASSWORD_REGEX.test(password);
    if (!v1 || !v2 || !v3) {
      setErrMsg("Invalid Entry");
      return;
    }
    registerUser({ name, email, password });
  };

  return (
    <section className="h-screen">
      <div className="px-6 mt-16 text-gray-800">
        <div className="flex justify-center items-center flex-wrap g-6">
          <div className="max-w-xs">
            <div className="flex justify-center py-8">
              <h1 className="text-2xl font-semibold">Register</h1>
            </div>
            {message && (
              <Message variant={message.variant} text={message.text} />
            )}
            {errMsg && <Message variant="danger" text={errMsg} />}
            <form onSubmit={submitHandler}>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="flex items-center uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="name"
                  >
                    Username
                    <MdCheck
                      className={validName ? "text-green-500 ml-1" : "hidden"}
                      size={20}
                    />
                    <MdClose
                      className={
                        validName || !name ? "hidden" : "text-red-500 ml-1"
                      }
                      size={20}
                    />
                  </label>

                  <input
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    type="text"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="name"
                    ref={nameRef}
                    placeholder="Name"
                    autoComplete="off"
                    onFocus={() => setNameFocus(true)}
                    onBlur={() => setNameFocus(false)}
                  />
                  <p
                    id="uidnote"
                    className={
                      nameFocus && name && !validName
                        ? "text-white bg-black text-sm p-1 relative"
                        : "hidden"
                    }
                  >
                    <MdInfoOutline />
                    4 to 24 characters.
                    <br />
                    Must begin with a letter.
                    <br />
                    Letters, numbers, underscores, hyphens allowed.
                  </p>
                </div>
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="flex items-center uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="email"
                  >
                    Email
                    <MdCheck
                      className={validEmail ? "text-green-500 ml-1" : "hidden"}
                      size={20}
                    />
                    <MdClose
                      className={
                        validEmail || !email ? "hidden" : "text-red-500 ml-1"
                      }
                      size={20}
                    />
                  </label>
                  <input
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    type="text"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="email"
                    placeholder="Email address"
                    autoComplete="username"
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)}
                  />
                  <p
                    id="uidnote"
                    className={
                      emailFocus && email && !validEmail
                        ? "text-white bg-black text-sm p-1 relative"
                        : "hidden"
                    }
                  >
                    <MdInfoOutline />
                    Must be a valid email format.
                  </p>
                </div>
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="flex items-center uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="password"
                  >
                    Password
                    <MdCheck
                      className={
                        validPassword ? "text-green-500 ml-1" : "hidden"
                      }
                      size={20}
                    />
                    <MdClose
                      className={
                        validPassword || !password
                          ? "hidden"
                          : "text-red-500 ml-1"
                      }
                      size={20}
                    />
                  </label>
                  <input
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    type="password"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="password"
                    placeholder="Password"
                    autoComplete="new-password"
                    onFocus={() => setPasswordFocus(true)}
                    onBlur={() => setPasswordFocus(false)}
                  />
                  <p
                    id="uidnote"
                    className={
                      passwordFocus && password && !validPassword
                        ? "text-white bg-black text-sm p-1 relative"
                        : "hidden"
                    }
                  >
                    {" "}
                    <MdInfoOutline />
                    8 to 24 characters.
                    <br />
                    Must include uppercase and lowercase letters, a number and a
                    special character.
                    <br />
                    Allowed special characters: !@#$%
                  </p>
                </div>
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="flex items-center uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="confirmPassword"
                  >
                    Confirm Password
                    <MdCheck
                      className={
                        validMatch && confirmPassword
                          ? "text-green-500 ml-1"
                          : "hidden"
                      }
                      size={20}
                    />
                    <MdClose
                      className={
                        validMatch || !confirmPassword
                          ? "hidden"
                          : "text-red-500 ml-1"
                      }
                      size={20}
                    />
                  </label>
                  <input
                    value={confirmPassword}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                    type="password"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    onFocus={() => setMatchFocus(true)}
                    onBlur={() => setMatchFocus(false)}
                  />
                  <p
                    id="uidnote"
                    className={
                      matchFocus && confirmPassword && !validMatch
                        ? "text-white bg-black text-sm p-1 relative"
                        : "hidden"
                    }
                  >
                    <MdInfoOutline />
                    Must match the first password input field.
                  </p>
                </div>
              </div>
              <div className="text-center lg:text-left">
                <button
                  type="submit"
                  className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  {" "}
                  Register
                </button>
                <p className="text-sm mt-2 pt-1 mb-0">Have an account?</p>
                <Link
                  className="font-bold text-red-600 hover:text-red-700 focus:text-red-700 transition duration-200 ease-in-out"
                  to={redirect ? `/login?redirect=${redirect}` : "/login"}
                >
                  Login
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterScreen;
