// Disabled to allow textCard to be clickable as a <div> (initially used button causing text formatting issues)
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import TextNodeModal from "./TextNodeModal";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const TextNodeCard = ({ textNode }) => {
  // const navigate = useNavigate();
  const [isTextNodeOpen, setTextNodeIsOpen] = useState(false);
  const [textNodeLocal, setTextNodeLocal] = useState(textNode);
  const axiosPrivate = useAxiosPrivate();

  const toggleTextNode = () => {
    setTextNodeIsOpen(!isTextNodeOpen);
  };

  // If returning from editor, get the updated textNode from the database
  useEffect(() => {
    if (!isTextNodeOpen) {
      getTextNode();
    }
  }, [isTextNodeOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const getTextNode = async () => {
    try {
      const { data } = await axiosPrivate.get(
        `/api/textnodes/${textNodeLocal.textNodeId}`
      );
      setTextNodeLocal(data);
    } catch (error) {
      // TODO: manage the errors
      return error;
    }
  };

  return (
    <>
      {isTextNodeOpen && (
        <TextNodeModal
          textNode={textNodeLocal}
          onRequestClose={toggleTextNode}
        />
      )}
      <button
        onClick={toggleTextNode}
        className="flex text-left align-top lg:w-128 sm:w-96 w-72  min-h-40 px-4 py-3 border-b-2 border-x-2 shadow-lg hover:bg-gray-200 focus:bg-gray-300 focus:outline-none"
      >
        <div
          className="content flex-1"
          dangerouslySetInnerHTML={{ __html: textNodeLocal.note }}
        ></div>
      </button>
      <div className="bg-green-50 py-2"></div>
    </>
  );
};

export default TextNodeCard;
