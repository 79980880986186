// Disabled to allow textCard to be clickable as a <div> (initially used button causing text formatting issues)
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { useNavigate } from "react-router-dom";
// import useAxiosPrivate from "../hooks/useAxiosPrivate";

const VideoNodeCard = ({ videoNode }) => {
  const navigate = useNavigate();

  const handleOpenNode = () => {
    navigate("/videonode", { state: { videoNode } });
  };

  return (
    <div className="p-1" onClick={handleOpenNode}>
      <img
        src={videoNode.uriThumbnail}
        alt={videoNode.photoNodeId}
        className="h-48"
      />
      <div className="text-xs w-36 text-ellipsis overflow-hidden">
        {videoNode.label}
      </div>
      <div className="bg-green-50 py-2"></div>
    </div>
  );
};

export default VideoNodeCard;
