import React from "react";

const Footer = () => {
  return (
    <div className="container mx-auto">
      {/* <footer className="text-xl font-bold">Footer</footer> */}
    </div>
  );
};

export default Footer;
