import React, { useState, useEffect } from "react";
import EventCard from "../components/EventCard";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";

const HomeScreen = () => {
  const location = useLocation();
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [eventList, setEventList] = useState({
    loading: false,
    events: [],
  });

  const navigate = useNavigate();
  const { events, loading, error } = eventList;

  const getEvents = async () => {
    try {
      if (!loading && events.length === 0) {
        setEventList((prevState) => ({ ...prevState, loading: true }));
        const { data } = await axiosPrivate.get(`/api/events`);
        setEventList({ loading: false, events: data });
      }
    } catch (error) {
      // console.log(`Error Response: ${JSON.stringify(error.response)}`);
      // Refresh token expired
      if (error.response?.status === 403) {
        navigate("/login", { state: { from: location }, replace: true });
      }
      setEventList({
        loading: false,
        events: [],
        error: error.response?.data?.message
          ? error.response.data.message
          : error.message,
      });
    }
  };

  const addNewEvent = () => {
    navigate("/createevent", { state: { from: location } });
  };

  useEffect(() => {
    if (!auth?.userId) {
      navigate("/login");
    } else {
      getEvents();
    }
  }, [auth]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className="h-screen bg-gray-100">
      <div className="mt-16 text-gray-800">
        <div className="flex flex-col justify-center items-center w-full bg-green-50">
          <div className="flex justify-center py-4">
            <div className="flex flex-row">
              <h1 className="text-2xl font-semibold">Events</h1>
              <button
                onClick={addNewEvent}
                className="ml-5 px-4 py-1 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              >
                Add Event
              </button>
            </div>
          </div>
          <div className="flex flex-col bg-gray-100 ">
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant="danger" text={error} />
            ) : (
              <div className="">
                {events.map((event) => (
                  <EventCard key={event.eventId} event={event} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeScreen;
