import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import useAuth from "../hooks/useAuth";
import ReactPlayer from "react-player";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const VideoNodeScreen = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const { videoNode } = location.state;

  const [videoUri, setVideoUri] = useState({
    loadingVideo: false,
    uri: null,
  });

  const { uri, loadingVideo, errorVideo } = videoUri;

  const getVideo = async (videNode) => {
    if (!loadingVideo && !uri) {
      try {
        const response = await axiosPrivate.get(
          `/api/videos/download/${videNode.id}`,
          { responseType: "arraybuffer" }
        );

        // console.log(response);
        const blob = new Blob([response.data], { type: "video/mp4" }); //
        const url = URL.createObjectURL(blob);
        // console.log(url);
        setVideoUri({
          loadingVideo: false,
          uri: url,
        });
      } catch (error) {
        // console.log(`Error Response: ${JSON.stringify(error.response)}`);
        // Refresh token expired
        if (error.response?.status === 403) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        setVideoUri({
          loadingVideo: false,
          uri: null,
          errorVideo: error.response?.data?.message
            ? error.response.data.message
            : error.message,
        });
      }
    }
  };

  useEffect(() => {
    if (!auth?.userId) {
      navigate("/login");
    } else {
      getVideo(videoNode);
    }
  }, [auth, videoNode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="mt-20">
      {loadingVideo ? (
        <Loader />
      ) : errorVideo ? (
        <Message variant="danger" text={errorVideo} />
      ) : (
        <div>
          <ReactPlayer
            url={uri}
            alt={videoNode.videoNodeId}
            light={<img src={videoNode.uriThumbnail} alt="Thumbnail" />}
            controls
            playing
            width="300px"
            height="200px"
          />
          <div className="text-xs w-36 text-ellipsis overflow-hidden">
            {videoNode.label}
          </div>
        </div>
      )}
    </div>
  );
};
export default VideoNodeScreen;
