import React from "react";

const Message = ({ variant, text }) => {
  switch (variant) {
    case "danger":
      return (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 my-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">{text}</strong>
        </div>
      );
    case "success":
      return (
        <div
          className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 my-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">{text}</strong>
        </div>
      );
    default:
      return (
        <div
          className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 my-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">{text}</strong>
        </div>
      );
  }
};

Message.defaultProps = {
  variant: "info",
};

export default Message;
