import React, { useEffect, useState } from "react";
import EditText from "./EditText";
import ShowText from "./ShowText";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const TextNodeModal = ({ textNode, onRequestClose }) => {
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [textNodeLocal, setTextNodeLocal] = useState(textNode);
  const axiosPrivate = useAxiosPrivate();

  // Use useEffect to add an event listener to the document
  useEffect(() => {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        // Close the modal when the Escape key is pressed
        onRequestClose();
      }
    }
    // Prevent scolling
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onKeyDown);
    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // If returning from editor, get the updated textNode from the database
  useEffect(() => {
    if (!isEditorOpen) {
      getTextNode();
    }
  }, [isEditorOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleEditor = () => {
    setIsEditorOpen(!isEditorOpen);
  };

  const getTextNode = async () => {
    try {
      const { data } = await axiosPrivate.get(
        `/api/textnodes/${textNodeLocal.textNodeId}`
      );
      setTextNodeLocal(data);
    } catch (error) {
      // TODO: manage the errors
      return error;
    }
  };

  return (
    <div className="bg-black/[.45] inset-0 overflow-auto fixed z-1">
      <div className="w-[40rem]  bg-white rounded-md max-w-full p-3 mt-32 mb-12 mx-auto">
        {isEditorOpen ? (
          <EditText textNode={textNodeLocal} toggleEditor={toggleEditor} />
        ) : (
          <ShowText
            textNode={textNodeLocal}
            toggleEditor={toggleEditor}
            onRequestClose={onRequestClose}
          />
        )}
      </div>
    </div>
  );
};

export default TextNodeModal;
