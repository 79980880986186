import React, { useState, useEffect } from "react";
import SegmentCard from "../components/SegmentCard";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";

function EventScreen() {
  const location = useLocation();
  const event = location.state?.event;
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [segmentList, setSegmentList] = useState({
    loading: false,
    segments: [],
  });

  const navigate = useNavigate();
  const { segments, loading, error } = segmentList;

  const getSegments = async (event) => {
    try {
      if (!loading && segments.length === 0) {
        setSegmentList((prevState) => ({ ...prevState, loading: true }));
        const { data } = await axiosPrivate.get(`/api/segments`, {
          params: event,
        });
        setSegmentList({ loading: false, segments: data });
      }
    } catch (error) {
      // console.log(`Error Response: ${JSON.stringify(error.response)}`);
      // Refresh token expired
      if (error.response?.status === 403) {
        navigate("/login", { state: { from: location }, replace: true });
      }
      setSegmentList({
        loading: false,
        segments: [],
        error: error.response?.data?.message
          ? error.response.data.message
          : error.message,
      });
    }
  };

  const addNewSegment = () => {
    navigate("/createsegment", { state: { from: location, event } });
  };

  useEffect(() => {
    if (!auth?.userId) {
      navigate("/login");
    } else {
      getSegments(event);
    }
  }, [auth, event]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className="h-screen bg-gray-100">
      <div className="mt-16 text-gray-800">
        <div className="flex flex-col justify-center items-center w-full bg-green-50">
          <div className="flex justify-center py-4">
            <div className="flex flex-row">
              <h1 className="text-2xl font-semibold">{event?.name}</h1>
              <button
                onClick={addNewSegment}
                className="ml-5 px-4 py-1 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              >
                Add Segment
              </button>
            </div>
          </div>
          <div className="flex flex-col  bg-gray-100 ">
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant="danger" text={error} />
            ) : (
              <div className="">
                {segments.map((segment) => (
                  <SegmentCard
                    key={segment.segmentId}
                    segment={segment}
                    event={event}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default EventScreen;
